declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_CLIENT_CONFIG: string | object;
};

export const env: EnvType = {
  ...process.env,
  ...window.env,
};

if (!Object.keys(env).includes('REACT_APP_CLIENT_CONFIG')) {
  env.REACT_APP_CLIENT_CONFIG = process.env?.REACT_APP_CLIENT_CONFIG || window.env?.REACT_APP_CLIENT_CONFIG;
}

export default env;
