import { AppConfig, GlideConfig, IDPConfig } from '@virtus/common/auth';
import { env } from 'src/env';

export interface GlideAppConfig extends AppConfig {
  glide: GlideConfig;
  idp: IDPConfig;
  marketmapURLS?: string[];
  routePathsDisabled?: string[]; // Route toggling
  disableLivePrice?: boolean; // Feature toggling
  nexusLinks?: any;
}

const getConfigObject = (item: any) => {
  if (item !== null && typeof item === 'object') {
    return item;
  }
  let value = typeof item !== 'string' ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }
  if (value !== null && typeof value === 'object') {
    return value;
  }
};

const getConfig = (): GlideAppConfig => {
  let client_config;
  if (env.REACT_APP_CLIENT_CONFIG) {
    client_config = getConfigObject(env.REACT_APP_CLIENT_CONFIG);
  } else {
    throw new Error(`No config for __ENVIRONMENT__:${__ENVIRONMENT__} or hostname:${location?.hostname}`);
  }

  if (client_config && __ENVIRONMENT__.includes('local')) {
    return client_config[__ENVIRONMENT__];
  } else {
    return client_config;
  }
};

console.info('Active config name:', __ENVIRONMENT__ || location.hostname);
// console.info('Active config details:', getConfig());

export default getConfig();
